<template>
  <v-btn
    class="d-btn"
    :class="classNames"
    :elevation="elevation"
    :ripple="ripple"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot/>
    <template v-slot:loader>
      <slot name="loader"/>
    </template>
  </v-btn>
</template>

<script>
export default {
  props: {
    vertical: Boolean,
    align: String,
    ellipsis: Boolean,
    dense: Boolean,
    flex: Boolean,
    elevation: {
      type: [ Number, String ],
      default: 0
    },
    flat: {
      type: Boolean,
      default: true
    },
    ripple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classNames() {

      var align = '';
      if ([ 'start', 'left' ].includes( this.align )) align = 'justify-start';
      else if ([ 'end', 'right' ].includes( this.align )) align = 'justify-end';

      return {
        '--vertical': this.vertical,
        '--ellipsis': this.ellipsis,
        '--dense': this.dense,
        [align]: !!align
      }
    }
  }
}
</script>

<style>
.d-btn.--dense.v-size--x-large:not(.v-btn--fab):not(.v-btn--icon) {
  padding: 0 11px !important;
}
.d-btn.--dense.v-size--large:not(.v-btn--fab):not(.v-btn--icon) {
  padding: 0 9px !important;
}
.d-btn.--dense.v-size--default:not(.v-btn--fab):not(.v-btn--icon) {
  padding: 0 8px !important;
}
.d-btn.--dense.v-size--small:not(.v-btn--fab):not(.v-btn--icon) {
  padding: 0 6px !important;
}
.d-btn.--dense.v-size--x-small:not(.v-btn--fab):not(.v-btn--icon) {
  padding: 0 4px !important;
}
.d-btn.--vertical.v-size--x-large:not(.v-btn--fab):not(.v-btn--icon) {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}
.d-btn.--vertical.v-size--large:not(.v-btn--fab):not(.v-btn--icon) {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.d-btn.--vertical.v-size--default:not(.v-btn--fab):not(.v-btn--icon) {
  padding-top: 34px !important;
  padding-bottom: 34px !important;
}
.d-btn.--vertical.v-size--small:not(.v-btn--fab):not(.v-btn--icon) {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.d-btn.--vertical.v-size--x-small:not(.v-btn--fab):not(.v-btn--icon) {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.d-btn.--vertical.d-btn--dense.v-size--x-large:not(.v-btn--fab):not(.v-btn--icon) {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}
.d-btn.--vertical.d-btn--dense.v-size--large:not(.v-btn--fab):not(.v-btn--icon) {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.d-btn.--vertical.d-btn--dense.v-size--default:not(.v-btn--fab):not(.v-btn--icon) {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}
.d-btn.--vertical.d-btn--dense.v-size--small:not(.v-btn--fab):not(.v-btn--icon) {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.d-btn.--vertical.d-btn--dense.v-size--x-small:not(.v-btn--fab):not(.v-btn--icon) {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.v-btn:not(.v-btn--round).--vertical.v-size--x-large,
.v-btn:not(.v-btn--round).--vertical.v-size--large,
.v-btn:not(.v-btn--round).--vertical.v-size--default,
.v-btn:not(.v-btn--round).--vertical.v-size--small,
.v-btn:not(.v-btn--round).--vertical.v-size--x-small {
  height: auto;
}
.d-btn.--vertical > .v-btn__content {
  flex-direction: column;
}
.d-btn.justify-start > .v-btn__content,
.d-btn.justify-start > .v-btn__loader {
  justify-content: flex-start;
}
.d-btn.--vertical.justify-start > .v-btn__content {
  align-items: flex-start;
}
.d-btn.justify-end > .v-btn__content,
.d-btn.justify-end > .v-btn__loader {
  justify-content: flex-end;
}
.d-btn.--vertical.justify-end > .v-btn__content {
  align-items: flex-end;
}
.d-btn.justify-start > .v-btn__loader {
  padding-left: inherit;
}
.d-btn.justify-end > .v-btn__loader {
  padding-right: inherit;
}
.d-btn.--ellipsis.v-btn--block {
  max-width: 100% !important;
}
.d-btn.--ellipsis.v-btn--block > .v-btn__content {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.d-btn.--ellipsis.--vertical > .v-btn__content {
  max-width: 100%;
  overflow: hidden;
  align-items: stretch;
  text-align: center;
}
.d-btn:not(.--vertical).--ellipsis.v-btn--block > .v-btn__content {
  display: block;
  text-align: center;
}
.d-btn.--ellipsis.justify-start > .v-btn__content {
  text-align: left !important;
}
.d-btn.--ellipsis.justify-end > .v-btn__content {
  text-align: right !important;
}
</style>
